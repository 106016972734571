import React from "react";
import styles from "./Footer.module.css";
const Footer = () => {
  return (
    <footer className={styles.appFooter}>
      <div className={styles.footerSec}>
        <section className={styles.smallSec}>
          <div>
            <header>
              <h1 className={styles.footerTitle}>contact</h1>
            </header>
            <p className={styles.footerP}>GSM: <a className={styles.footerLink} href="tel:+32-477-88-42-02"> +32 (0) 477 88 42 02</a></p>
            <p className={styles.footerP}>E-mail: <a className={styles.footerLink} target="_blanc" href="mailto:info@azrepair.be">info@azrepair.be</a></p>
            <p className={styles.footerP}>Stuur ons gerust ook een berichtje op <a className={styles.footerLink} target="_blanc" href="https://www.facebook.com/azrepairbe/">Facebook</a></p>
            <p className={styles.footerP}>Adres: <a className={styles.footerLink} target="_blanc"
              href="https://www.google.com/maps/place/Bruggestraat+9,+8930+Menen/@50.7965546,3.1194681,17z/data=!3m1!4b1!4m5!3m4!1s0x47c32e06f340e5f7:0x667409a7a19080be!8m2!3d50.7965546!4d3.1216568"
            >Bruggestraat 9, 8930 Menen</a></p>
          </div>
        </section>
        <section className={styles.smallSec}>
          <a
            className={styles.footerAnc}
            target="_blanc"
            href="https://www.google.com/maps/place/Bruggestraat+9,+8930+Menen/@50.7965546,3.1194681,17z/data=!3m1!4b1!4m5!3m4!1s0x47c32e06f340e5f7:0x667409a7a19080be!8m2!3d50.7965546!4d3.1216568"
          >
            <img
              className={styles.imgBody}
              src={`./assets/map.png`}
              alt="map"
              width="369"
              height="241"
            />
          </a>
        </section>
        <section className={styles.smallSec}>
          <header>
            <h1 className={styles.footerTitle}>openingsuren</h1>
          </header>
          <p className={styles.footerP2}>Wij werken maandag t.e.m. zondag op afspraak</p>
        </section>
      </div>
      <section className={styles.footerSec2}>
        <article className={styles.footerSectionWidth}>
          <p className={styles.rightStyle}>© All rights reserved.</p>
        </article>
      </section>
    </footer>
  );
};
export default Footer;
