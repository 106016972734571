import React from "react";
import { Link } from "react-scroll";
import styles from "./Home.module.css";
import TabBar from "../components/TabBar";
import Footer from "../components/Footer";
import Button from "../components/Button";


const Home = () => {
  return (
    <>
      <div className="mainHeader">
        <div className="container" id="home">
          <TabBar />
        </div>
      </div>
      <main>
        <div className="container">
          <section className={styles.secMain}>
            <div className={styles.pageDivText1}>
              <header className={styles.homeHeader}>
                <h1 className={styles.homeTitle}>Auto diagnostiek</h1>
                <h2 className={styles.homeTitleH2}>Snel en flexibel</h2>
              </header>
              <p className={styles.homeBodyText}>
                Het team van AZ Repair beschikt over professionele
                diagnosemogelijkheden voor auto's, evenals een ultramoderne
                faciliteit om de problemen van uw auto snel op te lossen.
                Zo helpen we onze klanten opnieuw veilig op de weg, mét een tevreden gevoel.
              </p>
              <p className={styles.homeBodyText}>
                Wij repareren op professionele wijze auto- en vrachtwagen
                clusters in een breed scala voor zowel particulieren als
                bedrijven.
              </p>
              <Link

                to="moreInfoButton"
                spy={true}
                smooth={true}
                offset={-270}
                duration={700}
                className={styles.meerInfo}
              >
                Meer info
              </Link>
            </div>
            <div className={styles.pageDivFoto}>
              <img
                className={styles.img}
                src={`./assets/foto1.png`}
                alt="bannerfoto"
                width="950"
                height="550"
              />
            </div>
          </section>
          <blockquote className={styles.quoteSec}>
            <p className={styles.quoteText}>
              {" "}
              Revisie van auto-elektronica is bij ons professioneel, betaalbaar en klantgericht.
            </p>
          </blockquote>
          <section className={styles.extraSection}>
            <div className={styles.extraSecDiv} id="moreInfoButton">
              <div className={styles.divForCites}>
                <svg className={styles.myCiteImg} xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none" viewBox="0 0 70 70">
                  <path stroke="#4262F9" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M50.707 30.146c.444 1.544.687 3.173.687 4.854 0 9.633-7.814 17.447-17.447 17.447S16.5 44.633 16.5 35s7.814-17.447 17.447-17.447c2.982 0 5.794.75 8.248 2.072" />
                  <path stroke="#4262F9" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M44.383 35.38c-.211 5.763-5.044 10.268-10.806 10.056-5.763-.211-10.268-5.044-10.056-10.806.211-5.763 5.044-10.268 10.806-10.067M33.947 35l11.928-10.045m7.074-1.703c.56-.169.719-.687.36-1.142l-.783-1.004-1.3-1.66-.783-1.005c-.36-.455-.899-.423-1.195.074l-1.375 2.273-1.089 1.809-1.374 2.273c-.296.497-.096.761.465.592l2.538-.793 2.02-.634 2.537-.793-.021.01Z" />
                </svg>
              </div>
              <cite>&#x02500; &#x02007; Onze visie</cite>
              <p className={styles.extraPar}>We bieden <span className={styles.highText}>een standaard reparatieservice</span> aan waarbij we <span className={styles.highText}>op korte tijd </span> uw bestaande cluster herstellen. Indien u snel een vervangmodel nodig hebt, <span className={styles.highText}>voorzien we een omruilservice</span> waarbij we u dezelfde dag nog een vervangend cluster bezorgen!</p>
            </div>
            <div className={styles.extraSecDiv}>
              <div className={styles.divForCites}>
                <svg className={styles.myCiteImg} xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none" viewBox="0 0 70 70">
                  <path stroke="#4262F9" stroke-miterlimit="10" stroke-width="2" d="M45.635 34.488c0-5.942-4.814-10.756-10.756-10.756-5.941 0-10.756 4.814-10.756 10.756 0 4.292 5.378 9.025 5.378 9.025.42.368.768 1.137.768 1.7v2.582c0 .563.461 1.024 1.025 1.024h7.17c.564 0 1.025-.461 1.025-1.024v-2.582c0-.563.348-1.332.768-1.7 0 0 5.378-4.743 5.378-9.025Z" />
                  <path fill="#4262F9" d="M38.073 52.927h-6.402c-.566 0-1.024.458-1.024 1.024v1.024c0 .566.458 1.025 1.024 1.025h6.402c.566 0 1.025-.459 1.025-1.025v-1.024c0-.566-.459-1.024-1.025-1.024Z" />
                  <path stroke="#4262F9" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M35 14v4.098m-14.576 1.669 2.899 2.889m-8.811 10.295h4.569m36.406 0h-4.569M48.85 19.767l-2.899 2.889" />
                </svg>
              </div>
              <cite>&#x02500; &#x02007; Wij lossen het op</cite>
              <p className={styles.extraPar}>
                Onze reparatieservice voor instrumentenclusters lost veel
                veelvoorkomende storingen op, zoals een <span className={styles.highText}> defecte kilometerteller </span>, <span className={styles.highText}> dashboardmeter </span>, <span className={styles.highText}>snelheidsmeter</span>, <span className={styles.highText}>toerenteller</span>, <span className={styles.highText}>brandstofmeter</span> en <span className={styles.highText}>dimmen</span>, <span className={styles.highText}>flikkeren</span> of <span className={styles.highText}> defecte achtergrondverlichting. </span>
              </p>
            </div>
            <div className={styles.extraSecDiv}>
              <div className={styles.divForCites}>
                <svg className={styles.myCiteImg} xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none" viewBox="0 0 70 70">
                  <g clip-path="url(#a)">
                    <path stroke="#4262F9" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M29.852 40.533H67.94A2.065 2.065 0 0 0 70 38.474v-3.428c0-1.132-.907-2.244-2.019-2.47l-7.288-1.503c-1.112-.227-2.512-1.205-3.109-2.162l-1.935-3.099c-.597-.957-2.018-1.75-3.15-1.75H25.734" />
                    <path fill="#fff" d="M32.982 44.908a4.137 4.137 0 0 1-3.572-2.059c-1.132-1.966-.463-4.488 1.503-5.62a4.145 4.145 0 0 1 2.059-.556c1.472 0 2.84.792 3.572 2.059.545.957.7 2.059.412 3.13a4.143 4.143 0 0 1-1.915 2.5 4.145 4.145 0 0 1-2.059.556v-.01Z" />
                    <path fill="#4262F9" d="M32.981 37.702c1.102 0 2.131.597 2.677 1.544.411.71.525 1.545.308 2.348a3.065 3.065 0 0 1-1.44 1.873 3.124 3.124 0 0 1-1.545.412 3.104 3.104 0 0 1-2.676-1.544 3.062 3.062 0 0 1-.31-2.347 3.065 3.065 0 0 1 1.442-1.874 3.124 3.124 0 0 1 1.544-.412m0-2.058a5.149 5.149 0 0 0-4.457 7.72 5.16 5.16 0 0 0 4.457 2.574 5.149 5.149 0 0 0 4.457-7.72 5.16 5.16 0 0 0-4.457-2.574Z" />
                    <path fill="#fff" d="M61.25 44.908a4.137 4.137 0 0 1-3.572-2.059c-1.133-1.966-.464-4.488 1.503-5.62a4.145 4.145 0 0 1 2.058-.556c1.472 0 2.842.792 3.572 2.059.546.957.7 2.059.412 3.13a4.143 4.143 0 0 1-1.914 2.5 4.145 4.145 0 0 1-2.06.556v-.01Z" />
                    <path fill="#4262F9" d="M61.249 37.702c1.101 0 2.13.597 2.676 1.544.855 1.473.34 3.367-1.132 4.221a3.123 3.123 0 0 1-1.544.412 3.104 3.104 0 0 1-2.677-1.544c-.854-1.472-.34-3.366 1.133-4.22a3.123 3.123 0 0 1 1.544-.413Zm0-2.058a5.149 5.149 0 0 0-4.457 7.72 5.16 5.16 0 0 0 4.457 2.574 5.149 5.149 0 0 0 4.457-7.72 5.16 5.16 0 0 0-4.457-2.574Z" />
                    <path stroke="#4262F9" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M0 29.724h18.53M8.234 24.063h18.53M8.234 35.386h18.53" />
                  </g>
                  <defs>
                    <clipPath id="a">
                      <path fill="#fff" d="M0 0h70v70H0z" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <cite>&#x02500; &#x02007; Bezorgservice</cite>
              <p className={styles.extraPar}>
                <span className={styles.highText}>Binnen West-Vlaanderen</span> bieden wij een haal- en breng service. Het
                pakketje wordt <span className={styles.highText}>binnen de 24 uur</span> gereviseerd teruggebracht bij de
                opdrachtgever.</p>
              <p className={styles.extraPar}>Voor pakketjes <span className={styles.highText}>buiten West-Vlaanderen</span> werken wij
                samen met een aantal bezorgdiensten. Bij ontvangst worden de onderdelen dezelfde dag nog nagekeken, hersteld en opgestuurd richting de klant. Zo krijgt u gegarandeerd <span className={styles.highText}>binnen de 3 werkdagen</span> uw pakketje terug.</p>
            </div>
          </section>
          <section className={styles.bodySec}>
            <article className={styles.bodySecArt} id="sleutel">
              <div>
                <img
                  className={styles.imgBody}
                  src={`./assets/sleutel.png`}
                  alt="sleutel"
                  width="950"
                  height="550"
                />
              </div>
              <div className={styles.pageDivText}>
                <header className={styles.artHeader}>
                  <h1 className={styles.artTitle}>Sleutel/IMMO</h1>
                </header>
                <p className={styles.homeBodyText}>
                  Eén van onze specialiteiten is het maken van autosleutels. Of het nu een oude sleutel of een nieuwer model is, maakt voor ons niet uit. Met onze speciale sleutel- en transpondermachines creëren wij elke gewenste autosleutel, ook die met afstandsbediening.
                </p>
                <p className={styles.homeBodyText}>
                  Prijzen voor autosleutels zijn sterk afhankelijk van merk. Toch garanderen we u de laagste prijs en bovendien een snelle service.
                </p>
                <Button />
              </div>
            </article>
            <article className={styles.bodySecArt} id="ecu">
              <div>
                <img
                  className={styles.imgBody}
                  src={`./assets/eco.png`}
                  alt="radio"
                  width="950"
                  height="550"
                />
              </div>
              <div className={styles.pageDivText}>
                <header className={styles.artHeader}>
                  <h1 className={styles.artTitle}>ECU</h1>
                </header>
                <p className={styles.homeBodyText}>
                  Wil de auto niet starten of reageert de auto niet bij het
                  indrukken van het gaspedaal? Dan kunnen er problemen zijn met
                  de ECU (Electronic Control Unit). De ECU maakt gebruik van
                  diverse componenten zoals bondjes met een printplaat en is
                  daarom ook gevoeliger voor defecten en storingen. AZ Repair biedt
                  de mogelijkheid om de ECU te laten herstellen.
                </p>
                <p className={styles.homeBodyText}>
                  Wilt u een defecte ECU laten herstellen? Neem gerust contact op voor een vrijblijvend gesprek.
                </p>
                <Button />
              </div>
            </article>
            <article className={styles.bodySecArt} id="autoradio">
              <div>
                <img
                  className={styles.imgBody}
                  src={`./assets/radio.png`}
                  alt="radio"
                  width="950"
                  height="550"
                />
              </div>
              <div className={styles.pageDivText}>
                <header className={styles.artHeader}>
                  <h1 className={styles.artTitle}>Autoradio decoderen</h1>
                </header>
                <p className={styles.homeBodyText}>
                  De meeste originele autoradio’s en navigatiesystemen zijn
                  tegenwoordig voorzien van een antidiefstalcode. Dit is een unieke pincode voor elke radio. Indien de autoradio
                  beveiligd is met zo’n code zal er “SAFE” of “CODE” weergegeven
                  worden op het scherm en zal u de code opnieuw moeten invoeren om
                  gebruik te kunnen maken van de autoradio.
                </p>
                <p className={styles.homeBodyText}>
                  Wij zijn gespecialiseerd in het decoderen van autoradio’s van
                  diverse merken en modellen. Via onze dienst
                  heeft u de radiocode in slechts één minuut terug en kunt u weer genieten van de radio!
                </p>
                <Button />
              </div>
            </article>
            <article className={styles.bodySecArt} id="dashboard">
              <div>
                <img
                  className={styles.imgBody}
                  src={`./assets/abs.png`}
                  alt="abs"
                  width="950"
                  height="550"
                />
              </div>
              <div className={styles.pageDivText}>
                <header className={styles.artHeader}>
                  <h1 className={styles.artTitle}>Dashboard</h1>
                </header>
                <p className={styles.homeBodyText}>
                  Alle informatie in het dashboard is natuurlijk mooi zolang het werkt, maar wat als het display ineens minder goed gaat functioneren? Originele onderdelen zijn vaak erg duur, maar gelukkig bieden wij, AZ Repair, de mogelijkheid aan om het display te laten herstellen. Dode pixels, het uitvallen van regels en zelfs een display dat helemaal niet meer werkt, AZ Repair kan het allemaal! Een vaak voorkomend probleem is dat de connector los zit van het display zelf. Het vastlijmen is een welbekende oplossing hiervoor, maar dit werkt slechts tijdelijk. Wij opteren liever voor een duurzame oplossing waarbij we alles vervangen. De nieuwe platte kabels die we gebruiken, worden bovendien door ons gemaakt.
                </p>
                <Button />
              </div>
            </article>

            <article className={styles.bodySecArt} id="airbag">
              <div>
                <img
                  className={styles.imgBody}
                  src={`./assets/airb.png`}
                  alt="airb"
                  width="950"
                  height="550"
                />
              </div>
              <div className={styles.pageDivText}>
                <header className={styles.artHeader}>
                  <h1 className={styles.artTitle}>Airbag/SRS</h1>
                </header>
                <p className={styles.homeBodyText}>
                  Als u een ongeluk heeft gehad, kunt u uw airbag module opsturen. Deze wordt teruggezet naar de oorspronkelijke fabrieksinstellingen en binnen de 24 uur teruggestuurd. Wanneer u uw originele module opstuurt voor een reset, is deze 99% wanneer u deze terugkrijgt. In zeldzame gevallen, bijvoorbeeld bij hardware-problemen of indien het als nieuw model moet worden geprogrammeerd, kan kalibratie en programmering nodig zijn wanneer de module terug in het voertuig is.
                </p>
                <p className={styles.homeBodyText}>
                  Resultaat is 100% gegarandeerd. Toch niet tevreden? Dan ontvangt u uw geld onmiddellijk terug.
                </p>
                <Button />
              </div>
            </article>
          </section>
        </div>
        <section className={styles.serviceSp} id="service">
          <header className={styles.serviceHeader}>
            <h1 className={styles.homeTitleS}>service 24/7</h1>
            <h2 className={styles.homeTitleH2}>
              Voor contactsleutel/stuurslot Mercedes
            </h2>
          </header>
          <article className={styles.serviceArt}>
            <div className={styles.serviceDiv}>
              <p className={styles.homeBodyText2}>
                Volgens autobeoordelingen zijn Mercedes-Benz voertuigen en
                vrachtwagens gemaakt met de hoogste niveau van veiligheid en
                bescherming. Variërend van topklasse luxe sedans en SUV's, heeft
                de Duitse fabrikant ook een lijn van auto's voor lange
                afstanden. Maar eén ding dat echter bij verschillende auto's
                voorkomt, is de kans op verschillende hinderpalen.
              </p>
            </div>
            <div className={styles.serviceDiv}>
              <p className={styles.homeBodyText2}>
                Mercedes-Benz’ ontstekingsschakelaars, alarm- en startblokkeersystemen, alsook sloten kunnen het best door een professionele Mercedes-Benz slotenmaker worden onderhouden en gerepareerd.
                Bij AZ Repair hebben we de uitgebreide kennis en ervaring voor het bieden van hoogwaardige oplossingen voor alle slotgerelateerde problemen tegen eerlijke prijzen.
              </p>
            </div>
            <div className={styles.serviceDiv}>
              <p className={styles.homeBodyText2}>
                We bieden een grote variatie professionele slotenmakers aan,
                tegen eerlijke prijzen.
              </p>
              <p className={styles.serviceSpan}>
                Bel ons voor deskundige Mercedes-slotenmakers.
              </p>
              <span className={styles.serviceSpan}>
                <p className={styles.serviceSpan}>GSM: <a className={styles.serviceSpan2} href="tel:+32-477-88-42-02">+32 (0) 477 88 42 02</a>
                </p>

              </span>
              <br />
            </div>
          </article>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default Home;
