import React, { Component } from "react";
import RealNumber from "./RealNumber";

class Button extends Component {
  state = {
    show: true,
    actionBut: true,
    actionHide: true
  };

  handleClickImg = () => {
    this.setState({ show: !this.state.show });
    this.setState({ actionBut: !this.state.actionBut });
    this.setState({ actionHide: !this.state.actionHide });
    console.log("action button");
  };

  render() {
    const { show } = this.state;
    const { actionBut } = this.state;
    const { actionHide } = this.state;
    const className = show ? `active` : `nonactive`;
    const className2 = actionBut ? `nonpressed` : `pressed`;
    const className3 = actionHide ? `showTypo` : `hideTypo`;
    console.log("pressed");
    return (
      <>
        <RealNumber
          className={className}
          className2={className2}
          className3={className3}
          onClick={this.handleClickImg}
        />
      </>
    );
  }
}
export default Button;
