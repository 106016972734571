import { slide as Menu } from "react-burger-menu";
import styles from "./TabBar.module.css";
// import { Link } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

import React, { Component } from "react";

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = { menuOpen: false };
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  render() {
    return (
      <>
        <div className={styles.myHamburger}>
          <span onClick={() => this.toggleMenu()} className={styles.openHamb}>
            &#9776;
          </span>
          <Menu
            customBurgerIcon={false}
            right
            width={"100%"}
            disableOverlayClick
            noOverlay
            className={styles.myMenuBg}
            isOpen={this.state.menuOpen}
            onStateChange={state => this.handleStateChange(state)}
          >
            <ul className={styles.myUlHamb}>
              <span
                onClick={() => this.toggleMenu()}
                className={styles.closeBtn}
              >
                &times;
              </span>
              <li>
                <Link
                  onClick={() => this.closeMenu()}
                  exact="true"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={70}
                  duration={700}
                  activeclassname={styles.active}
                  activeClass={styles.active}
                >
                  start
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.closeMenu()}
                  activeclassname={styles.active}
                  to="sleutel"
                  spy={true}
                  smooth={true}
                  offset={-170}
                  duration={700}
                  activeClass={styles.active}
                >
                  Sleutel/immo
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.closeMenu()}
                  activeclassname={styles.active}
                  to="ecu"
                  spy={true}
                  smooth={true}
                  offset={-170}
                  duration={700}
                  activeClass={styles.active}
                >
                  ecu
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.closeMenu()}
                  activeclassname={styles.active}
                  to="autoradio"
                  spy={true}
                  smooth={true}
                  offset={-170}
                  duration={700}
                  activeClass={styles.active}
                >
                  autoradio
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.closeMenu()}
                  activeclassname={styles.active}
                  to="dashboard"
                  spy={true}
                  smooth={true}
                  offset={-170}
                  duration={700}
                  activeClass={styles.active}
                >
                  dashboard
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.closeMenu()}
                  activeclassname={styles.active}
                  to="airbag"
                  spy={true}
                  smooth={true}
                  offset={-170}
                  duration={700}
                  activeClass={styles.active}
                >
                  airbag/srs
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.closeMenu()}
                  activeclassname={styles.active}
                  to="service"
                  spy={true}
                  smooth={true}
                  offset={-170}
                  duration={700}
                  activeClass={styles.active}
                >
                  service 24/7
                </Link>
              </li>
            </ul>
          </Menu>
        </div>
      </>
    );
  }
}

export default NavbarPage;
