import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import logo from "../logo.svg";
import styles from "./App.module.css";
import { ROUTES } from "../constants";
import Home from "./Home";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path={ROUTES.home} exact strict component={() => <Home />} />
      </Switch>
    );
  }
}

export default App;