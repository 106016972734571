import React from "react";
// import { NavLink } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import styles from "./TabBar.module.css";
// import { ROUTES } from "../constants";
import NavbarPage from "./NavbarPage";

const TabBar = () => {
  return (
    <header className={styles.appHeader}>
      <nav className={styles.tabBar}>
        <Link
          exact="true"
          to="home"
          spy={true}
          smooth={true}
          offset={-170}
          duration={700}
          className={styles.appLogo}
        >
          <svg className={styles.mainLogo} xmlns="http://www.w3.org/2000/svg" width="361" height="109" fill="none" viewBox="0 0 361 109">
            <path fill="#1010C2" d="M115.235 79V65.629h10.334L134.932 79H147l-10.889-15.429c3.191-1.371 5.653-3.348 7.387-5.931 1.733-2.583 2.6-5.657 2.6-9.223 0-3.566-.855-6.651-2.566-9.257-1.71-2.606-4.149-4.617-7.317-6.034-3.167-1.417-6.9-2.126-11.2-2.126H104v48h11.235Zm8.69-22H115V40h8.925c3.291 0 5.793.732 7.506 2.195C133.144 43.658 134 45.76 134 48.5c0 2.694-.856 4.784-2.569 6.27-1.713 1.487-4.215 2.23-7.506 2.23ZM191 79v-8.914h-26.009V58.977h22.186v-8.64h-22.186V39.914h25.122V31H154v48h37Zm20.209 0V65.766h9.756c4.29 0 8.015-.697 11.175-2.092 3.16-1.394 5.593-3.405 7.3-6.034 1.707-2.629 2.56-5.703 2.56-9.223 0-3.566-.853-6.651-2.56-9.257s-4.14-4.617-7.3-6.034C228.98 31.709 225.255 31 220.965 31H200v48h11.209Zm9.716-22H212V40h8.925c3.291 0 5.793.735 7.506 2.204 1.713 1.469 2.569 3.58 2.569 6.331 0 2.705-.856 4.792-2.569 6.261-1.713 1.47-4.215 2.204-7.506 2.204Zm29.494 22 4.265-10.286h22.357L281.306 79H293l-21.531-48h-11.007L239 79h11.419ZM274 60h-15l7.5-18 7.5 18Zm35 19V31h-11v48h11Zm20.235 0V65.629h10.334L348.932 79H361l-10.889-15.429c3.191-1.371 5.653-3.348 7.387-5.931 1.733-2.583 2.6-5.657 2.6-9.223 0-3.566-.855-6.651-2.566-9.257-1.71-2.606-4.149-4.617-7.317-6.034-3.167-1.417-6.9-2.126-11.2-2.126H318v48h11.235Zm9.69-22H330V40h8.925c3.291 0 5.793.732 7.506 2.195C348.144 43.658 349 45.76 349 48.5c0 2.694-.856 4.784-2.569 6.27-1.713 1.487-4.215 2.23-7.506 2.23Z" />
            <path fill="#6C62F0" fill-rule="evenodd" d="m56.768 79 31.732.001-.489.654-.582.749-.083.104c-.336.422-.678.838-1.028 1.248l-.656.755-.504.559-.645.692-.574.594-.603.602 17.493 20.929-23.28-.018-9.192-10.84-.34.144-.598.247-.707.28-.44.17c-.388.145-.778.286-1.17.423l-.763.258-.666.214-.503.155-.857.25-.572.157-.633.166-.853.208-.625.143-.65.139-.606.121-.777.144-.792.134-.59.09-.77.108-.688.085-.672.074-.84.08-.734.057-.705.045-.626.031-.819.03-.72.014L49 99l-.461-.002-.793-.014-.885-.03-.52-.025-.754-.046-.797-.061-.845-.08-.962-.108-.679-.088-.814-.118-.473-.075-.767-.132-.8-.151-.763-.157-.174-.039L56.768 79ZM42.669 68.703l3.416 6.59-15.477 20.138a49.164 49.164 0 0 1-21.63-17.153l4.964-9.575H42.67Zm53.822-30.816a48.784 48.784 0 0 1 1.067 5.505A49.32 49.32 0 0 1 98 50c0 3.01-.271 5.956-.791 8.816l-.135.714a48.625 48.625 0 0 1-1.902 6.915l-.243.668H73.602l22.18-31.73a48.8 48.8 0 0 1 .71 2.504ZM49 1c18.003 0 33.738 9.709 42.255 24.175l-.734.825H53.617v11.887h21.219L57.48 60.468 37.914 26H18.989L.703 58.316A49.322 49.322 0 0 1 0 50C0 22.938 21.938 1 49 1ZM29 40l8.423 17H20.129L29 40Z" clip-rule="evenodd" />
            <path fill="#1010C2" fill-rule="evenodd" d="m56.768 79 31.732.001-.489.654-.582.749-.083.104c-.336.422-.678.838-1.028 1.248l-.656.755-.504.559-.645.692-.574.594-.603.602 17.493 20.929-23.28-.018-9.192-10.84-.34.144-.598.247-.707.28-.44.17c-.388.145-.778.286-1.17.423l-.763.258-.666.214-.503.155-.857.25-.572.157-.633.166-.853.208-.625.143-.65.139-.606.121-.777.144-.792.134-.59.09-.77.108-.688.085-.672.074-.84.08-.734.057-.705.045-.626.031-.819.03-.72.014L49 99l-.461-.002-.793-.014-.885-.03-.52-.025-.754-.046-.797-.061-.845-.08-.962-.108-.679-.088-.814-.118-.473-.075-.767-.132-.8-.151-.763-.157-.174-.039L56.768 79Zm39.015-43.617a48.583 48.583 0 0 1 1.775 8.008A49.37 49.37 0 0 1 98 50c0 3.01-.271 5.956-.791 8.816l-.135.714a48.625 48.625 0 0 1-1.902 6.915l-.243.668H73.602l22.18-31.73Z" clip-rule="evenodd" />
            <path fill="red" fill-rule="evenodd" d="m26.041 106.195 51.033-66.397 1.176 4.299L114.455 3.41 69.601 67.578l-1.047-5.339-42.513 43.956Z" clip-rule="evenodd" />
          </svg>


        </Link>
        <div className={styles.navDiv}>
          <Link
            activeclassname={styles.active}
            to="sleutel"
            spy={true}
            smooth={true}
            offset={-170}
            duration={700}
            activeClass={styles.active}
          >
            Sleutel/immo
          </Link>

          <Link
            activeclassname={styles.active}
            to="ecu"
            spy={true}
            smooth={true}
            offset={-170}
            duration={700}
            activeClass={styles.active}
          >
            ecu
          </Link>
          <Link
            activeclassname={styles.active}
            to="autoradio"
            spy={true}
            smooth={true}
            offset={-170}
            duration={700}
            activeClass={styles.active}
          >
            autoradio
          </Link>

          <Link
            activeclassname={styles.active}
            to="dashboard"
            spy={true}
            smooth={true}
            offset={-170}
            duration={700}
            activeClass={styles.active}
          >
            dashboard
          </Link>

          <Link
            activeclassname={styles.active}
            to="airbag"
            spy={true}
            smooth={true}
            offset={-170}
            duration={700}
            activeClass={styles.active}
          >
            airbag/srs
          </Link>
          <Link
            activeclassname={styles.active}
            to="service"
            spy={true}
            smooth={true}
            offset={-170}
            duration={700}
            activeClass={styles.active}
          >
            service 24/7
          </Link>

        </div>
        <NavbarPage />
      </nav>
    </header>
  );
};
export default TabBar;
