import React from "react";
import styles from "../containers/Home.module.css";

const RealNumber = ({ className, className2, className3, onClick }) => {
  return (
    <>
      <button className="meerInfoSp" onClick={onClick}>
        <span className={className2}> &rarr;</span>
        <span className={className3}> Contacteer ons voor meer info.</span>
        &#8195;
      </button>
      <span className={className} alt="gsm" title="call">
        <a href="tel:+32-477-88-42-02" className={styles.phoneNumber}>GSM: +32 (0) 477 88 42 02</a>
      </span>
    </>
  );
};

export default RealNumber;
